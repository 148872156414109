
$pn1: #2E3440;
$pn2: #3B4252;
$pn3: #434C5E;
$pn4: #4C566A;
$prim: #53e3a6;


.bg-bubbles-container {
	width: 100%;
	height: 100%;
	background: $pn2;
	background: linear-gradient(to bottom right, $pn1 0%, $pn3 100%);
	position: fixed;
}

.bg-bubbles{
	padding: 0;
	margin: 0;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	opacity: .2;
	overflow: hidden;

	li{
		position: absolute;
		list-style: none;
		display: block;
		width: 40px;
		height: 40px;
		background-color: rgba(255,255,255,.15);
		bottom: -400px;

		-webkit-animation: square 45s infinite;
		animation:         square 45s infinite;

		-webkit-transition-timing-function: linear;
		transition-timing-function: linear;

		&:nth-child(1){
			left: 10%;
		}

		&:nth-child(2){
			left: 20%;

			width: 80px;
			height: 80px;

			animation-delay: 4s;
			animation-duration: 27s;
		}

		&:nth-child(3){
			left: 25%;
			animation-delay: 8s;
		}

		&:nth-child(4){
			left: 40%;
			width: 60px;
			height: 60px;

			animation-duration: 32s;

			background-color: rgba(255,255,255,.15);
		}

		&:nth-child(5){
			left: 70%;
		}

		&:nth-child(6){
			left: 80%;
			width: 120px;
			height: 120px;

			animation-delay: 6s;
			background-color: rgba(255,255,255,.2);
		}

		&:nth-child(7){
			left: 32%;
			width: 160px;
			height: 160px;

			animation-delay: 14s;
		}

		&:nth-child(8){
			left: 55%;
			width: 20px;
			height: 20px;

			animation-delay: 30s;
			animation-duration: 80s;
		}

		&:nth-child(9){
			left: 25%;
			width: 10px;
			height: 10px;

			animation-delay: 4s;
			animation-duration: 80s;
			background-color: fade(white, 30%);
		}

		&:nth-child(10){
			left: 90%;
			width: 160px;
			height: 160px;

			animation-delay: 22s;
		}
		&:nth-child(11){
			border-radius:20px;
			left: 15%;
		}

		&:nth-child(12){
			left: 30%;
			border-radius:40px;

			width: 80px;
			height: 80px;

			animation-delay: 6s;
			animation-duration: 30s;
		}

		&:nth-child(13){
			left: 35%;
			border-radius:20px;
			animation-delay: 12s;
		}

		&:nth-child(14){
			left: 35%;
			width: 60px;
			height: 60px;
			border-radius:30px;

			animation-duration: 36s;
			animation-delay:2s;

			background-color: rgba(255,255,255,.15);
		}

		&:nth-child(15){
			left: 35%;
			border-radius: 40px
		}

		&:nth-child(16){
			left: 40%;
			width: 120px;
			height: 120px;
			border-radius: 60px;

			animation-delay: 14s;
			background-color: rgba(255,255,255,.25);
		}

		&:nth-child(17){
			left: 22%;
			width: 160px;
			border-radius: 80px;
			height: 160px;

			animation-delay: 6s;
		}

		&:nth-child(18){
			left: 52%;
			width: 20px;
			height: 20px;
			border-radius: 10px;

			animation-delay: 24s;
			animation-duration: 40s;
		}

		&:nth-child(19){
			left: 25%;
			width: 10px;
			height: 10px;
			border-radius: 5px;

			animation-delay: 4s;
			animation-duration: 80s;
			background-color: rgba(255,255,255,.35);
		}

		&:nth-child(10){
			left: 90%;
			width: 160px;
			border-radius: 80px;
			height: 160px;

			animation-delay: 22s;
		}
	}
}

@-webkit-keyframes square {
  0%   { transform: translateY(0); }
  100% { transform: translateY(-1500px) rotate(600deg); }
}
@keyframes square {
  0%   { transform: translateY(0); }
  100% { transform: translateY(-3000px) rotate(600deg); }
}