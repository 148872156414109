.note {
  margin-top: 2em;
  font-size: .8em;
  text-align: center;
}
@media screen and (min-width: 600px) {
  .nav-download {
    margin-top: 6em;
  }
}
.nav-download .btn {
  background-color: rgba(255,255,255, .09)
}

.instructions {
  margin: 1em auto;
  margin-top: 4em;
  width: 30em;
  code {
    display: block;
    font-family: monospace;
    white-space: pre;
    margin: 3em 0 3em 1em;
  }
}