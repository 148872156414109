@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300);
@import url(https://fonts.googleapis.com/css2?family=Aldrich&display=swap);

*{
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

a {
	text-decoration: none;
	color: white;
}

body{
	font-family: 'Source Sans Pro', sans-serif;
	color: white;
	font-size: 1.3rem;

	::-webkit-input-placeholder { /* WebKit browsers */
		font-family: 'Source Sans Pro', sans-serif;
		color:    white;
	}
	:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
		font-family: 'Source Sans Pro', sans-serif;
		color:    white;
		opacity:  1;
	}
	::-moz-placeholder { /* Mozilla Firefox 19+ */
		font-family: 'Source Sans Pro', sans-serif;
		color:    white;
		opacity:  1;
	}
	:-ms-input-placeholder { /* Internet Explorer 10+ */
		font-family: 'Source Sans Pro', sans-serif;
		 color:    white;
		font-weight: 300;
	}
}
$pn1: #2E3440;
$pn2: #3B4252;
$pn3: #434C5E;
$pn4: #4C566A;


.App {
	width: 100%;
	height: 100%;
	margin: 0 auto;
	position: absolute;
	padding: 1em 2em 2em;
}
.container {
	max-width: 1200px;
	margin: 0 auto;
}
.nav-list {
	list-style-type: none;
	.btn {
		margin: 0 auto;
	}
	li {
		margin-bottom: .2em;
	}
}


.github {
	top: 1em;
	right: 1em;
	display: none;
	position: absolute;
}

@media  (min-width: 600px) {
	.github {
		display: block;
	}
	.nav-list {
		display: flex;
		justify-content: space-around;
	}
	h1 {
		 font-size: 3.5em;
	}
}

h1{
	text-align: center;
	font-family: 'Aldrich', Roboto, Arial, Helvetica, sans-serif;
	text-transform: uppercase;
	transition-duration: 1s;
	transition-timing-function: ease-in-put;
	font-weight: 200;
	line-height: 2;
}

.gh-icon {
	width: 1.5em;
	opacity: .5;
	height: auto;
}

.gh-icon:hover {
	width: 1.5em;
	opacity: .9;
	height: auto;
}