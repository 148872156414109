.getting-started-steps {
  margin: .6em auto 0;
  width: 75%;
  a {
    color: #81A1C1;
    font-weight: bold;
  }
  li {
    font-size: 1.2em;
  }
}
@media (min-width: 600px) {
  .getting-started-steps {
    margin: 2em auto 0;
    li {
      font-size: 1.6em;
    }
  }
}