.btn {
	line-height: 2em;
	height: 2em;
	text-align: center;
	width: 11em;
	cursor: pointer;
	font-size: 1.3em;
}
@media (min-width: 600px) {
	.btn {
		line-height: 2.3em;
		height: 2.3em;
		text-align: center;
		width: 11em;
		cursor: pointer;
		font-size: 1.5em;
	}
}

/*
========================
      BUTTON ONE
========================
*/
.btn-one {
	color: #FFF;
	transition: all 0.3s;
	position: relative;
}
.btn-one span {
	transition: all 0.3s;
}
.btn-one::before {
	content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	opacity: 0;
	transition: all 0.3s;
	border-top-width: 1px;
	border-bottom-width: 1px;
	border-top-style: solid;
	border-bottom-style: solid;
	border-top-color: rgba(255,255,255,0.5);
	border-bottom-color: rgba(255,255,255,0.5);
	transform: scale(0.1, 1);
}
.btn-one:hover span {
	letter-spacing: 2px;
}
.btn-one:hover::before {
	opacity: 1;
	transform: scale(1, 1);
}
.btn-one::after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	transition: all 0.3s;
	background-color: rgba(255,255,255,0.1);
}
.btn-one:hover::after {
	opacity: 0;
	transform: scale(0.1, 1);
}


/*
========================
      BUTTON TWO
========================
*/
.btn-two {
	color: #FFF;
	transition: all 0.5s;
	position: relative;
}
.btn-two span {
	z-index: 2;
	display: block;
	position: absolute;
	width: 100%;
	height: 100%;
}
.btn-two::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	transition: all 0.5s;
	border: 1px solid rgba(255,255,255,0.2);
	background-color: rgba(255,255,255,0.1);
}
.btn-two::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	transition: all 0.5s;
	border: 1px solid rgba(255,255,255,0.2);
	background-color: rgba(255,255,255,0.1);
}
.btn-two:hover::before {
  transform: rotate(-45deg);
  background-color: rgba(255,255,255,0);
}
.btn-two:hover::after {
  transform: rotate(45deg);
  background-color: rgba(255,255,255,0);
}


/*
========================
      BUTTON THREE
========================
*/
.btn-three {
	color: #FFF;
	transition: all 0.5s;
	position: relative;
}
.btn-three::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	background-color: rgba(255,255,255,0.1);
	transition: all 0.3s;
}
.btn-three:hover::before {
	opacity: 0 ;
	transform: scale(0.5,0.5);
}
.btn-three::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	opacity: 0;
	transition: all 0.3s;
	border: 1px solid rgba(255,255,255,0.5);
	transform: scale(1.2,1.2);
}
.btn-three:hover::after {
	opacity: 1;
	transform: scale(1,1);
}
